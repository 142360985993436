import React, { useEffect } from "react";
import { Page } from "../components/page";
import { ProjectContainer, Project } from "../components/project";
import { PhoneNumber } from "../components/phoneNumber";
import frame from "../assets/frame.jpg";
import kitchen from "../assets/kitchen.jpg";
import trim from "../assets/trim.jpg";
import patio from "../assets/patio.jpg";
import barnDoor from "../assets/bar-door.jpg";
import buildIn from "../assets/build-in.jpg";
import deck from "../assets/deck.jpg";

export const Home: React.FC = () => {
  useEffect(() => {
    document.title = "Paragon Construction, LLC | Home";
  }, []);

  return (
    <Page>
      <h2 className="Heading">
        With more than 15 years of residential construction experience, Paragon
        Construction is the right choice for your next project!{" "}
        <PhoneNumber tag="span" />
      </h2>
      <ProjectContainer>
        <Project>
          <h3 className="Heading">Kitchens</h3>
          <img className="Image" src={kitchen} alt="Kitchen interior" />
        </Project>
        <Project>
          <h3 className="Heading">Trim and Crown Molding</h3>
          <img className="Image" src={trim} alt="Example of trim and crown molding on a ceiling" />
        </Project>
        <Project>
          <h3 className="Heading">Decks</h3>
          <img className="Image" src={deck} alt="Deck in back of a house" />
        </Project>
        <Project>
          <h3 className="Heading">Built-Ins</h3>
          <img className="Image" src={buildIn} alt="A built-in entertainment center" />
        </Project>
        <Project>
          <h3 className="Heading">Barn Doors</h3>
          <img className="Image" src={barnDoor} alt="A barn door" />
        </Project>
        <Project>
          <h3 className="Heading">Covered Patios</h3>
          <img
            className="Image"
            src={patio}
            alt="A new covered patio"
          />
        </Project>
      </ProjectContainer>
      <h2 className="Heading">Contractors!</h2>
      <h3 className="Heading">
        We can help complete your next subdivision! <PhoneNumber tag="span" />
      </h3>
      <img src={frame} alt="New construction" className="Image" />
    </Page>
  );
};
