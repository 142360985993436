import React from "react";
import './project.css';

export const ProjectContainer: React.FC = ({ children }) => {
  return <div className="ProjectContainer">{children}</div>;
};

export const Project: React.FC = ({ children }) => {
  return <div className="ProjectWrapper">{children}</div>;
};
