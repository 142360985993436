import React from "react";
import "./footer.css";

type Props = {
  children: React.ReactNode;
};

export const Footer: React.FC<Props> = ({ children }) => {
  return (
    <footer id="footer" className="Footer">
      {children}
    </footer>
  );
};
