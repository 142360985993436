import React from "react";
import "./phoneNumber.css";

type Props = {
  tag?: keyof JSX.IntrinsicElements;
};

const defaultTag: keyof JSX.IntrinsicElements = "h2";

export const PhoneNumber: React.FC<Props> = ({ tag = defaultTag }) => {
  const phoneNumber = "636-432-8918";
  const Tag = tag;
  return (
    <Tag className="PhoneNumber">
      <a
        href={`tel:${phoneNumber.split("-").join("")}`}
        className="PhoneNumber_Link"
      >{`Call us | ${phoneNumber}`}</a>
    </Tag>
  );
};
