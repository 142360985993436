import React from "react";
import { PhoneNumber } from "./phoneNumber";
import { ReactComponent as ParagonLogo } from "../assets/paragon-logo.svg";
import "./brand.css";

export const Brand: React.FC = () => {
  return (
    <div>
      <div className="Brand">
        <p className="Brand_Super">Custom Homes</p>
        <ParagonLogo className="Brand_Logo" />
        <PhoneNumber />
        <hr className="Brand_Border" />
      </div>
    </div>
  );
};
