import React from "react";
import { Brand } from "./brand";
import { Footer } from "./footer";
import "./page.css";

type Props = {
  children?: React.ReactNode;
};

export const Page: React.FC<Props> = ({ children }: Props) => {
  return (
    <div id="page-wrapper" className="PageWrapper">
      <Brand />
      <div id="main" className="Main">
        {children}
      </div>
      <Footer>
        <p>&copy; 2021 Paragon Construction, LLC.</p>
      </Footer>
    </div>
  );
};
