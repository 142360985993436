import React, { useEffect } from "react";
import { Page } from "../components/page";

export const About = () => {
  useEffect(() => {
    document.title = "Paragon Construction, LLC | About";
  }, []);
  return (
    <Page>
      <h1>About</h1>
    </Page>
  );
};
